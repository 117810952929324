import React, { useState } from 'react'

import RecipeCard from '../../shared/RecipeCard'
import IngredientTile from '../../shared/IngredientTile';

const YouMightlike: React.FC = (data) => {
  const { sectionIngredients, recipes, scorableIngredients } = data;
  const [selectedRecipe, setSelectedRecipe] = useState(null)
  const ingredientsToScore = scorableIngredients
  const sortedRecipes = matchAndSortRecipes(ingredientsToScore, recipes)

  const { recomendedIngredients, recomendedRecipes } = findRecomendedRecipeAndIngredients(sectionIngredients, sortedRecipes, ingredientsToScore)
  if (!selectedRecipe || recomendedRecipes.findIndex(recipe => recipe.slug == selectedRecipe.slug) == -1) {
    setSelectedRecipe(recomendedRecipes[0])
  }
  const recipeRecomendedIngredients = recomendedIngredients.filter((ingredient) => {
    return (selectedRecipe || [])?.ingredients?.find((recipeIngredient) => {
      return recipeIngredient.ingredient?.sku == ingredient?.sku
    })
  })

  if (recomendedIngredients && selectedRecipe) {
    return (
      <>
        <div className="row py-2 my-2 bg-brand-dark">
          <div className="col-12">
            <h4>Here are a few things you can make based on your selections</h4>
          </div>
        </div>
        <div className="row mb-3">
          {recomendedRecipes && recomendedRecipes.map(recipe => (
            <div className="col-6 col-sm-3" key={`recipe-you-might-like-${recipe.slug}`}>
              <RecipeCard recipe={recipe} selectRecipe={setSelectedRecipe} selected={selectedRecipe.slug == recipe.slug} />
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-12 mt-3">
            <h4>Add these ingredients to round out this recipe</h4>
            <h4>We’ll include the recipe card with your delivery</h4>
          </div>
          {(recipeRecomendedIngredients || []).map((recomendedIngredient) => (
            <IngredientTile ingredient={recomendedIngredient} recipes={[selectedRecipe]} className="" key={`recomended-ingredient-${recomendedIngredient.slug}`} />
          ))}
        </div>
      </>
    )
  } else {
    return ""
  }
}

const findRecomendedRecipeAndIngredients = (sectionIngredients, sortedRecipes, cartContents) => {
  const recipes = sortedRecipes.filter((recipe) => {
    return findRecomendedIngredients(sectionIngredients, [recipe], cartContents).length > 0;
  }).slice(0, 3)

  if (recipes.length > 0) {
    const ingredients = findRecomendedIngredients(sectionIngredients, recipes, cartContents);

    return {
      recomendedRecipes: recipes,
      recomendedIngredients: ingredients
    }
  } else {
    return {
      recomendedRecipes: null,
      recomendedIngredients: null
    }
  }
}

const matchAndSortRecipes = (cartContents, recipes) => {
  const scoredRecipes = recipes.map((recipe) => {
    return {
      recipe: recipe,
      score: scoreRecipe(cartContents, recipe)
    };
  });

  return scoredRecipes.sort((a, b) => {
    return b.score - a.score;
  }).map((recipeData) => {
    return recipeData.recipe;
  })
}

const scoreRecipe = (cartContents, recipe) => {
  if (typeof recipe.ingredients === 'undefined') {
    return 0;
  }


  const recipeScore = (recipe.ingredients || []).reduce((accumulator, ingredient) => {
    const cartElement = findInCart(cartContents, ingredient)

    if (cartElement) {
      return accumulator + 1;
    } else {
      return accumulator;
    }
  }, 0);

  return recipeScore
}

const findRecomendedIngredients = (ingredients, recipes, cartContents) => {
  return ingredients.filter((ingredient) => {
    return recipes.filter((recipe) => {
      return recipe.ingredients &&
        recipe.ingredients.find((recipeIngredient) => {
          return recipeIngredient?.ingredient?.sku == ingredient.sku
        })
    }).length > 0
      && findInCart(cartContents, { ingredient: ingredient }) == undefined
  })
}

const findInCart = (cartContents, ingredient) => {
  return cartContents.find((item) => {
    return item == ingredient?.ingredient?.sku;
  })
}

export default YouMightlike;
