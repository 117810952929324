import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import IndexLayout from '../../layouts/'
import IngredientTile from '../../components/shared/IngredientTile'
import { CartContext } from '../../components/shared/CartProvider'
import HowItWorks from '../../components/shared/LandingComponents/HowItWorks'
import YouMightLike from '../../components/shared/YouMightLike'

import Groceries from '../../images/svgs/groceries.svg'
import Saucepan from '../../images/svgs/saucepan.svg'
import Vegetables from '../../images/svgs/vegetables.svg'

const sectionedIngredients = [
  {
    name: "Grains",
    skus: [
      "MI-RANCHO-FLOUR-TORTILLA",
      "BOBS-BULGUR-24",
      "BAKERY-BEAD-LOAF",
      "MONTEBELLO-FARFALLE",
      "ALTER-ECO-ORGANIC-QUINOA"
    ]
  },
  {
    name: "Plant Proteins",
    skus: [
      "WESTBREAORGANICGARBANZO",
      "BEYOND-BEEF-PLANT-BASED-GROUND",
      "HOUSE-FOODS-SOFT-TOFU",
      "tempeh-lightlife",
      "lentils-westbrae"
    ]
  },
  {
    name: "Fresh Produce",
    skus: [
      "YELLOWONION",
      "FRESH-BROCCOLI",
      "FRESH-EGGPLANT",
      "FRESH-GREEN-CABBAGE",
      "FRESH-CAULIFLOWER-HEAD",
      "lacinato-kale",
      "PRODUCE-SWISS-CHARD",
      "FRESH-ORGANIC-SWEET-POTATO",
      "FRESH-GARLIC"
    ]
  },
  {
    name: "Flavors and Staples",
    skus: [
      "TAHINI",
      "HLTHPUNK-SMOKED-HARISSA",
      "SPICEWALA-BERBERE",
      "COLD-MOUNTAIN-WHITE-MISO-PASTE",
      "BIANCA-DINAPOLI-CRUSHED-TOMATOES",
      "nooch-bragg"
    ]
  }
]

const PlantBasedStarter = ({ data }) => {
  const [pageIngredients, setPageIngredients] = useState({})
  const [clearCart, setClearCart] = useState(true);

  useEffect(() => {
    setClearCart(false);
  });

  const changeAmountCallback = (ingredient, addRemove) => {
    if (addRemove == 'add') {
      pageIngredients[ingredient.sku] = (pageIngredients[ingredient.sku] || 0) + 1
    } else if (addRemove == 'remove' && pageIngredients[ingredient.sku] && pageIngredients[ingredient.sku] == 1) {
      delete pageIngredients[ingredient.sku]
    } else if (addRemove == 'remove' && pageIngredients[ingredient.sku]) {
      pageIngredients[ingredient.sku] = pageIngredients[ingredient.sku] - 1
    }

    setPageIngredients({ ...pageIngredients })
  }

  const decoratedSectionedIngredients = sectionedIngredients.map((section) => {
    const ingredientsForSection = data.allContentfulSellableIngredient.nodes.filter((ingredient) => {
      return section.skus.indexOf(ingredient.sku) != -1
    })

    return ({
      name: section.name,
      ingredients: ingredientsForSection
    })
  })

  return (
    <IndexLayout>
      <div className="section header bg-light">
        <div className="container bg-light">
          <div className="header-index bg-light mb-0 pb-1">
            <div className="row">
              <div className="col-12 col-md-7 py-4 d-flex flex-column justify-content-center">
                <h1 className="font-weight-bold display-1">
                  Plant-based products & groceries delivered!
                </h1>
                <h4>
                  Quickly build your grocery list
                </h4>
              </div>
              <div className="col-12 col-md-4">
                <img className="img-fluid bowl" alt="Eating Plants" src={data.groceryImage.childImageSharp.fluid.src} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <HowItWorks cellData={[
        { icon: Vegetables, header: "Pick a few items from each category" },
        { icon: Saucepan, header: "We’ll suggest some ways to use them" },
        { icon: Groceries, header: "Pick a day and time for delivery" }
      ]} />
      <section className="pick-ingredients">
        <div className="container">
          {decoratedSectionedIngredients.map((section) => (
            <div className="row my-3" key={`section-${section.name}`}>
              <div className="col-12">
                <h3 className="font-weight-bold my-3">{section.name}</h3>
              </div>
              {section.ingredients.map((ingredient) => (
                <IngredientTile ingredient={ingredient} key={`ingredient-section-key-${ingredient.sku}`} changeCallback={changeAmountCallback} />
              ))}
            </div>
          ))}
          <YouMightLike recipes={data.allContentfulRecipe.nodes}
            sectionIngredients={data.allContentfulSellableIngredient.nodes}
            scorableIngredients={Object.keys(pageIngredients)}
          />
          <CartContext.Consumer>
            {(cart) => {
              return (
                <div className="row pt-4">
                  <div className="col-8 col-md-4 mx-auto text-center">
                    <button className="btn btn-primary btn-block" onClick={cart.toggleCart}>View Cart</button>
                  </div>
                </div>
              )
            }}
          </CartContext.Consumer>
        </div>
      </section>
    </IndexLayout >
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        description
      }
    }
    groceryImage: file(relativePath: { eq: "images/Ingredients.png" }) {
      size
      childImageSharp {
        fluid {
          src
          sizes
        }
      }
    }
    allContentfulRecipe(filter: {nonProduction: {ne: true}}) {
      nodes {
        name
        slug
        time
        servings
        homepageDescription {
          childMarkdownRemark {
            html
          }
        }
        ingredients {
          ingredient {
            sku
          }
        }
        rectangularImage {
          fluid(maxWidth: 250) {
            src
          }
        }
      }
    }
    allContentfulSellableIngredient {
			nodes {
        name
        sku
        priceCents
        id
        slug
        section
        quantityDescription
        picture {
          fluid(quality: 100, maxWidth:100) {
            src
          }
        }
      }
    }
  }
`

export default PlantBasedStarter
